import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "required-non-blocked-urls"
    }}>{`Required non-blocked URLs`}</h1>
    <p>{`Some local firewall configuration may prevent accessing below links. Those links
are required to work with AutoBackup.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://widget.ybug.io"
        }}>{`https://widget.ybug.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.googletagmanager.com"
        }}>{`https://www.googletagmanager.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.datadoghq-browser-agent.com"
        }}>{`https://www.datadoghq-browser-agent.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cdn.mouseflow.com"
        }}>{`https://cdn.mouseflow.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cdnjs.cloudflare.com"
        }}>{`https://cdnjs.cloudflare.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.gstatic.com"
        }}>{`https://fonts.gstatic.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.googleapis.com"
        }}>{`https://fonts.googleapis.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ybug.io"
        }}>{`https://ybug.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lh3.googleusercontent.com"
        }}>{`https://lh3.googleusercontent.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.rollbar.com"
        }}>{`https://api.rollbar.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.backup.nordcloudapp.com"
        }}>{`https://api.backup.nordcloudapp.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.google-analytics.com"
        }}>{`https://www.google-analytics.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rum-http-intake.logs.datadoghq.com"
        }}>{`https://rum-http-intake.logs.datadoghq.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://o2.mouseflow.com"
        }}>{`https://o2.mouseflow.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ybug-uploads-prod.s3.eu-central-1.amazonaws.com"
        }}>{`https://ybug-uploads-prod.s3.eu-central-1.amazonaws.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://login.nordcloudapp.com"
        }}>{`https://login.nordcloudapp.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      